import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import axios from "axios";
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";

export default function Emailverifyforgetpass() {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/change_password`
        ,
        formDataToSend
      );

      console.log("Response data:", response.data);

      if (response.status === 200) {
        toast.success(response.data.message, "Sign In Successful!");

        setTimeout(() => {
          // navigate("/chat");
        }, 1000);
      } else {
        toast.error("Unexpected response status");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.details.message);
        toast.error(error.response.data.details.message);
      } else {
        setError("Something went wrong. Please try again.");
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleGetStartedClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    } else {
      navigate("/signin");
    }
  };
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  useEffect(() => {
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    const scriptElements = scripts.map((src) => loadScript(src));

    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <div className="my-app home-seven">
        <NavBar />

        <section
          className="section cmn-banner"
          data-background="assets/images/banner/banner-bg.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="cmn-banner__content wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <h3 className="h3">Forgot Password</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Forgot Password
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="anime">
            <img
              src="assets/images/anime-one.png"
              alt="Image"
              className="one"
            />
            <img
              src="assets/images/anime-two.png"
              alt="Image"
              className="two"
            />
          </div>
        </section>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              marginTop: "50px",
            },
          }}
        />
        <section className="section authentication pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-6">
                <div
                  className="authentication__inner wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <div className="section__header text-start">
                    <h2 className="h3">Forgot Password</h2>
                  </div>
                  <form onSubmit={handleSubmit} action="#" method="post">
                    <div className="input-single">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder=" Email address"
                        style={{ textTransform: "none" }}
                        required
                      />
                    </div>

                    <div className="form-cta">
                      <button
                        type="submit"
                        aria-label="post comment"
                        className="btn btn--ocotonary"
                        // onClick={handleGetStartedClick}
                      >
                        Submit{" "}
                      </button>
                    </div>
                  </form>
                  {error && <p className="error">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      <script src="/assets/js/jquery-3.7.0.min.js"></script>
      <script src="/assets/js/bootstrap.bundle.min.js"></script>
      <script src="/assets/js/jquery.nice-select.min.js"></script>
      <script src="/assets/js/jquery.magnific-popup.min.js"></script>
      <script src="/assets/js/slick.min.js"></script>
      <script src="/assets/js/typed.umd.js"></script>
      <script src="/assets/js/wow.min.js"></script>
      <script src="/assets/js/plugins.js"></script>
      <script src="/assets/js/main.js"></script>
    </div>
  );
}
