import React, { useState } from 'react';
import Chatbot from './Chatbot';     
import Firstchat from './Firstchat';

function ChatApp() {
  const [question, setQuestion] = useState(null);

  const handleQuestionSubmit = (newQuestion) => {
    setQuestion(newQuestion);  
  };

  return (
    <div>
      {question ? (
        <Chatbot initialQuestion={question} />  
      ) : (
        <Firstchat onSubmit={handleQuestionSubmit} /> 
      )}
    </div>
  );
}

export default ChatApp;
