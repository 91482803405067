// import React from "react";
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const GoogleAuth = () => {
//     const navigate = useNavigate();

//     const handleSuccess = async (credentialResponse) => {
//         const authToken = credentialResponse.credential; 
//         console.log(authToken, "authToken");
//         try {
//           const response = await axios.post(
//             "http://192.168.100.9:8000/user/api/google_login",
//             { auth_token: authToken },
//             { headers: { "Content-Type": "application/json" } } 
//           );
    
//           console.log("API Response:", response.data); 
    
//           if (response.status === 200) {
//             const { details } = response.data.result;
//             const { is_verified, token, display_popup, email, username } = details;
    
//             if (is_verified) {
//               const access_token = token.access; 
//               localStorage.setItem("token", access_token); 
//               localStorage.setItem("email", email);
//               localStorage.setItem("username", username);
//               localStorage.setItem("display_popup", JSON.stringify(display_popup));
    
//               console.log("User verified and token saved. Navigating to Chat...");
//               setTimeout(() => {
//                 navigate("/"); 
//               }, 1000);
//             } else {
//               console.error("User not verified");
//             }
//           }
//         } catch (error) {
//           console.error("Error during Google authentication:", error);
//         }
//       };

//   const handleError = () => {
//     console.error("Login Failed");
//   };

//   return (
//     <GoogleOAuthProvider clientId="504141748571-mctrq9aqcjcpbi7296bbr49fdtihpt01.apps.googleusercontent.com">
//       <div style={{ textAlign: "center", marginTop: "50px" }}>
//         {/* <h1>Google OAuth with React</h1> */}
//         <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
//       </div>
//     </GoogleOAuthProvider>
//   );
// };

// export default GoogleAuth;



// import React from "react";
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const GoogleAuth = () => {
//   const navigate = useNavigate();

//   const handleSuccess = async (credentialResponse) => {
//     const authToken = credentialResponse.credential;
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/user/api/google_login` ,

//         // "http://192.168.100.9:8000/user/api/google_login",
//         { auth_token: authToken },
//         { headers: { "Content-Type": "application/json" } }
//       );

//       if (response.status === 200) {
//         const { details } = response.data.result;
//         const { is_verified, token, display_popup, email, username } = details;

//         if (is_verified) {
//           const access_token = token.access;
//           localStorage.setItem("token", access_token);
//           localStorage.setItem("email", email);
//           localStorage.setItem("username", username);
//           localStorage.setItem("display_popup", JSON.stringify(display_popup));

//           setTimeout(() => {
//             navigate("/");
//           }, 1000);
//         } else {
//           console.error("User not verified");
//         }
//       }
//     } catch (error) {
//       console.error("Error during Google authentication:", error);
//     }
//   };

//   const handleError = () => {
//     console.error("Login Failed");
//   };

//   const styles = {
 
//     button: {
//       width: "500px",
//       height: "50px",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       border: "none",
   
//       cursor: "pointer",
//     },
//     buttonHover: {
  
//     },
//   };

//   return (
//     <GoogleOAuthProvider clientId="504141748571-mctrq9aqcjcpbi7296bbr49fdtihpt01.apps.googleusercontent.com">
     
//         <div
//           style={styles.button }
//           onMouseEnter={(e) => {
//             e.target.style.boxShadow = styles.buttonHover.boxShadow;
//           }}
//           onMouseLeave={(e) => {
//             e.target.style.boxShadow = styles.button.boxShadow;
//           }}
//         >
//           <GoogleLogin style={{border:"none" }}  onSuccess={handleSuccess} onError={handleError} />
//         </div>
     
//     </GoogleOAuthProvider>
//   );
// };

// export default GoogleAuth;


import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const GoogleAuth = () => {
  const navigate = useNavigate();

  const handleSuccess = async (credentialResponse) => {
    const authToken = credentialResponse.credential;
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/google_login`,
        { auth_token: authToken },
        { headers: { "Content-Type": "application/json" } }
      );
  
      console.log(authToken, "authToken");
      console.log(response, "GoogleAuth");
  
      if (response.data.status === 200) {
        const { details } = response.data.result || {};
        const { is_verified, token, display_popup, email, username } = details || {};
  
        if (is_verified) {
          const access_token = token.access;
          localStorage.setItem("token", access_token);
          localStorage.setItem("email", email);
          localStorage.setItem("username", username);
          localStorage.setItem("display_popup", JSON.stringify(display_popup));
  
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(response.data.message );
        }
      } else {
        toast.error(response.data.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error during Google authentication:", error);
  
      // Check if the error response contains specific details
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Error logging in with Google.");
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  

  const handleError = () => {
    console.error("Login Failed");
  };

  return (
    <div>
      <Toaster
              position="top-right"
              reverseOrder={false}
              toastOptions={{
                style: {
                  marginTop: "50px",
                },
              }}
            />
    <GoogleOAuthProvider clientId="504141748571-mctrq9aqcjcpbi7296bbr49fdtihpt01.apps.googleusercontent.com">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "100vh",
        }}
      >
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          shape="pill" 
          theme="outline" 
          size="large" 
          text="signin_with" 
          // width="300px" 
          style={{
            borderRadius: "25px", // Makes the button's existing borders rounded
            overflow: "hidden", // Ensures no extra visual effects are added
            height: "50px", // Maintains default height
            cursor: "pointer", // Adds hover pointer
            transition: "transform 0.2s ease", // Smooth hover effect
            borderColor:"transparent",
            border:"none"
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = "scale(1.02)"; // Slight zoom on hover
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = "scale(1)"; // Reset zoom
          }}
        />
      </div>
    </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleAuth;
