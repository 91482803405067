import React, { useEffect, useState } from "react";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import {
  FaHeart,
  FaTint,
  FaRunning,
  FaBed,
  FaAppleAlt,
  FaQuoteLeft,
  FaUser,
  FaBrain,
  FaShieldVirus,
  FaSmile,
} from "react-icons/fa";
import CreateProfile from "./Createprofile";

export default function Dashboard() {
  const [dailyAlert, setDailyAlert] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [profileExists, setProfileExists] = useState(null); 
  const [healthDetails, setHealthDetails] = useState(null); 
  const [personalInfo, setPersonalInfo] = useState(null); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/user_health_dashboard`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response.data, "API Response");

        if (response.data.profileExists === false) {
          setProfileExists(false);
        } else {
          setProfileExists(true);
          setHealthDetails(response.data.healthDetails);
          setPersonalInfo(response.data.personal_info);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setProfileExists(false);
      }
    };

    fetchData();
  }, []);

  // Set daily alert message
  useEffect(() => {
    if (healthDetails?.alertMessages) {
      const todayAlert =
        healthDetails.alertMessages[
          new Date().getDate() % healthDetails.alertMessages.length
        ];
      setDailyAlert(todayAlert);
    }
  }, [healthDetails]);

  // If profile does not exist, show the CreateProfile component
  if (profileExists === false) {
    return (
      <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[450px] md:w-[calc(100%-450px)]" : "w-full"
        }`}
      >
        <div className="max-w-7xl w-full px-5 py-10">
          <CreateProfile onProfileCreated={() => setProfileExists(true)} />
        </div>
      </div>
    );
  }

  // If data is still loading, show a loading message
  if (!healthDetails || !personalInfo) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />

      <div
        className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
          isSidebarOpen ? "md:ml-[450px] md:w-[calc(100%-450px)]" : "w-full"
        }`}
      >
        <div className="max-w-7xl w-full px-5 py-10">
          {/* Header Section */}
          <div className="flex justify-between items-center bg-white p-6 rounded-lg shadow-md mb-10">
            <div>
              <h1 className="text-4xl font-bold text-blue-800">
                Welcome to Your Health Dashboard
              </h1>
              <p className="text-gray-500">{new Date().toLocaleDateString()}</p>
            </div>
            {/* <img
              src="https://images.unsplash.com/photo-1517841905240-472988babdf9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=80&w=80"
              alt="Profile"
              className="w-16 h-16 rounded-full shadow-lg border-2 border-blue-600"
            /> */}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Health Stats */}
            {healthDetails.heartRate && healthDetails.bloodPressure && healthDetails.glucoseLevel && (
              <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaHeart className="text-red-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Health Stats</h2>
                </div>
                <p className="text-gray-600">Heart Rate: {healthDetails.heartRate} BPM</p>
                <p className="text-gray-600">Blood Pressure: {healthDetails.bloodPressure}</p>
                <p className="text-gray-600">Glucose Level: {healthDetails.glucoseLevel}</p>
              </div>
            )}

            {/* User Personal Info */}
            {personalInfo && (
              <div className="bg-teal-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaUser className="text-teal-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">User Personal Info</h2>
                </div>
                <p className="text-gray-600">Age: {personalInfo.age}</p>
                <p className="text-gray-600">Weight: {personalInfo.weight} kg</p>
                <p className="text-gray-600">Height: {personalInfo.height}</p>
                <p className="text-gray-600">Medication: {personalInfo.medication || "None"}</p>
                <p className="text-gray-600">Diseases: {personalInfo.diseases || "None"}</p>
              </div>
            )}

            {/* Meditation & Mindfulness */}
            {healthDetails.meditation && (
              <div className="bg-purple-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all lg:row-span-2">
                <h2 className="text-2xl font-bold mb-4">Meditation & Mindfulness</h2>
                <p className="text-gray-700">{healthDetails.meditation}</p>
                <div className="mt-6 bg-purple-200 p-4 rounded-lg border-l-4 border-purple-500">
                  <p className="text-lg font-semibold text-purple-700">Quote of the Day</p>
                  <p className="italic text-gray-700">
                    <FaQuoteLeft className="inline-block mr-2" />
                    {healthDetails.quote}
                  </p>
                </div>
              </div>
            )}

            {/* Exercise Routine */}
            {healthDetails.exerciseRoutine && (
              <div className="bg-green-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaRunning className="text-green-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">{healthDetails.exerciseRoutine.title}</h2>
                </div>
                {/* <p className="text-gray-600">{healthDetails.exerciseRoutine.description}</p> */}
                <ul className="mt-4 list-disc pl-5 space-y-2 text-gray-700">
                  {healthDetails.exerciseRoutine.suggestions.map((tip, index) => (
                    <li key={index}>{tip}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Sleep Management */}
            {healthDetails.sleepManagement && (
              <div className="bg-yellow-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaBed className="text-yellow-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">{healthDetails.sleepManagement.title}</h2>
                </div>
                {/* <p className="text-gray-600">{healthDetails.sleepManagement.description}</p> */}
                <ul className="mt-4 list-disc pl-5 space-y-2 text-gray-700">
                  {healthDetails.sleepManagement.suggestions.map((tip, index) => (
                    <li key={index}>{tip}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Diet Chart */}
            {healthDetails.dietChart && (
              <div className="bg-orange-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all lg:col-span-2">
                <div className="flex items-center mb-4">
                  <FaAppleAlt className="text-orange-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Balanced Diet Chart</h2>
                </div>
                {/* <p className="text-gray-600">{healthDetails.dietChart.description}</p> */}
                <div className="mt-4 text-gray-700">
                {healthDetails.dietChart.suggestions.map((meal, index) => (
                    <li key={index}>{meal}</li>
                  ))}
                  {/* {healthDetails.dietChart.suggestions.map((meal, index) => (
                    <div key={index} className="mb-4">
                      <p className="font-medium">{meal.meal}</p>
                      <p>{meal.items}</p>
                    </div>
                  ))} */}
                </div>
              </div>
            )}

            {/* Water Intake */}
            {healthDetails.waterIntake && (
              <div className="bg-blue-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaTint className="text-blue-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Water Intake</h2>
                </div>
                <p className="text-lg text-gray-700">{healthDetails.waterIntake}</p>
                <div className="mt-4 bg-white p-4 rounded-lg border-l-4 border-blue-500">
                  <p className="font-semibold text-blue-700">Daily Alert</p>
                  <p className="text-gray-600">{dailyAlert}</p>
                </div>
              </div>
            )}

            {/* Stress Management */}
            {healthDetails.stressManagement && (
              <div className="bg-pink-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaBrain className="text-pink-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Stress Management</h2>
                </div>
                <p className="text-gray-600">{healthDetails.stressManagement}</p>
              </div>
            )}

            {/* Disease Management */}
            {healthDetails.diseaseManagement && (
              <div className="bg-indigo-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaShieldVirus className="text-indigo-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Disease Management</h2>
                </div>
                <p className="text-gray-600">{healthDetails.diseaseManagement}</p>
              </div>
            )}

            {/* Feel Better Tips */}
            {healthDetails.feelFreeTips && (
              <div className="bg-green-200 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="flex items-center mb-4">
                  <FaSmile className="text-green-500 text-3xl mr-3" />
                  <h2 className="text-2xl font-bold">Feel Better Tips</h2>
                </div>
                <p className="text-gray-600">{healthDetails.feelFreeTips}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}