

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Mainsection() {
  const [submittedQuestion, setSubmittedQuestion] = useState("");
  const { t, i18n } = useTranslation();
  const [showIntro, setShowIntro] = useState(true);
  const navigate = useNavigate();

  const handleQuestionSubmit = (question) => {
    setSubmittedQuestion(question);
    setShowIntro(false);
  };

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    changeLanguage(language);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleReset = () => {
    setSubmittedQuestion("");
    setShowIntro(true);
  };

  useEffect(() => {
    const intervalId = setInterval(changeText, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const texts = ["Healthcare", "Biology", "Medicine"];
  const [slideIndex, setSlideIndex] = useState(0);

  const changeText = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
  };
  const handleButtonClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    } else {
      navigate("/signup");
    }
  };

  const token = localStorage.getItem("token");

  return (
    <div>
      {" "}
      <section class="banner-seven">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <div
                class="banner-seven__content text-center wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="300ms"
              >
                <h1 class="text-uppercase fw-8">MedicalGPT</h1>
                <p class="text-lg fw-5">
                MedicalGPT for Accurate and Reliable Medical Information, Powered by Advanced AI Technology.

                </p>
                <div class="nav__uncollapsed-item d-md-flex  justify-center items-center mt-8 ">
                  <a
                    class="btn btn--secondary"
                    style={{ backgroundColor: "#4569e7", borderRadius: "10px" }}
                    onClick={handleButtonClick}
                  >
                    {token ? "Dashboard" : "Get Started"}
                    <i
                      style={{ marginLeft: "8px" }}
                      class="fa-solid fa-arrow-right-to-bracket"
                    ></i>
                  </a>
                </div>
            
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </div>
  );
}
