import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Navigatingreliable() {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "assets/js/jquery-3.7.0.min.js",
      "assets/js/bootstrap.bundle.min.js",

      "assets/js/jquery.nice-select.min.js",
      "assets/js/jquery.magnific-popup.min.js",
      "assets/js/slick.min.js",
      "assets/js/typed.umd.js",
      "assets/js/wow.min.js",
      "assets/js/plugins.js",
      "assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <body>
        {/* <!-- ==== page wrapper start ==== --> */}
        <div class="my-app home-seven">
          {/* <!-- ==== header start ==== --> */}
          <Navbar />
          {/* <!-- ==== / header end ==== -->
          
          
         
            <!-- ==== footer start ==== --> */}
          {/* <!-- ==== / footer end ==== --> */}/
          <section
            class="section cmn-banner"
            data-background="assets/images/banner/banner-bg.png"
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    class="cmn-banner__content wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <h3 class="h3">
                      Navigating Reliable Medical Information in the Digital Age
                    </h3>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Blog Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="anime">
              <img src="assets/images/anime-one.png" alt="Image" class="one" />
              <img src="assets/images/anime-two.png" alt="Image" class="two" />
            </div>
          </section>
          <section class="section blog-main blog-details pb-0">
            <div class="container">
              <div class="row items-gap">
                <div class="col-12 col-xl-8">
                  <div class="blog-details__content">
                    <div
                      class="bd-thumb wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <img src="assets/images/news/eighttt.png" alt="Image" />
                    </div>
                    <div class="bd-content">
                      <div class="bd-meta">
                        <div class="meta__left">
                          <p>
                            <strong>Written by:</strong>
                            Marry biden
                          </p>
                          <span></span>
                          <p>10/01/2023</p>
                        </div>
                      </div>
                      <div
                        class="bd-content-info wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <h4 class="h4">
                          Navigating Reliable Medical Information in the Digital
                          Age
                        </h4>
                        <div class="paragraph">
                          <p>
                            In the digital age, information is more accessible
                            than ever before. With just a few clicks, you can
                            find articles, videos, and forums on virtually any
                            health topic. However, this abundance of information
                            comes with a significant challenge: determining what
                            is reliable and what is not. The spread of
                            misinformation, particularly in the medical field,
                            can have serious consequences. Inaccurate or
                            outdated information can lead to poor health
                            decisions, unnecessary anxiety, and even harm. To
                            navigate this complex landscape, it’s essential to
                            have a trustworthy resource that provides accurate,
                            evidence-based medical information. MedicalGPT is
                            that resource.
                          </p>
                          <p>
                            MedicalGPT is designed to help users find reliable
                            information in a sea of content. By drawing from a
                            vast database of up-to-date medical knowledge,
                            MedicalGPT ensures that the information you receive
                            is accurate, relevant, and trustworthy. Whether
                            you’re researching a specific condition, exploring
                            treatment options, or simply looking to improve your
                            overall health, MedicalGPT provides comprehensive
                            insights that you can rely on.
                          </p>
                        </div>
                     
                      </div>
                    </div>
                  
                    <div
                      class="bd-content wow fadeInUp"
                      style={{ marginTop: "-100px" }}
                      data-wow-duration="600ms"
                      data-wow-delay="1200ms"
                    >
                      <div class="bd-content__alt">
                        <p>
                          One of the biggest challenges in the digital age is
                          the proliferation of health misinformation. With so
                          many sources of information available, it can be
                          difficult to distinguish between credible sources and
                          those that are not. MedicalGPT addresses this issue by
                          using AI to analyze and cross-reference multiple
                          sources of information, ensuring that the advice you
                          receive is based on the latest scientific research and
                          best practices in healthcare.
                        </p>
                       
                      </div>
                    </div>
                    <div class="bd-quote">
                      <blockquote class="h4">
                        <q class="h4">
                          Preventive care is the foundation of a healthy
                          future—start today.
                        </q>
                      </blockquote>
                    </div>
                    <div
                      class="bd-content wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="900ms"
                    >
                      <div class="bd-content__alt mt-0 mt-4">
                        <p>
                          For healthcare providers, MedicalGPT serves as a
                          valuable assistant, offering evidence-based
                          recommendations and supporting clinical
                          decision-making. The tool can analyze patient data in
                          real time, helping providers identify potential issues
                          and make informed decisions quickly. This is
                          particularly beneficial in time-sensitive situations,
                          where prompt and accurate information is crucial.
                        </p>
                        <p className="mt-3">
                          One of the most significant advantages of MedicalGPT
                          in telemedicine is its ability to provide personalized
                          care remotely. By analyzing a patient’s medical
                          history, current symptoms, and other relevant factors,
                          MedicalGPT can offer tailored advice and treatment
                          plans. This ensures that patients receive care that is
                          specific to their needs, even when they are not
                          physically present in a healthcare facility.
                        </p>
                        <p className="mt-3">
                          MedicalGPT is particularly valuable for individuals
                          who are not medical professionals but want to take
                          control of their health. The tool provides
                          easy-to-understand explanations of complex medical
                          concepts, making it accessible to a wide audience.
                          This empowers users to make informed decisions about
                          their health and take proactive steps to manage their
                          well-being.
                        </p>
                        <p className="mt-3">
                          In conclusion, navigating the world of medical
                          information in the digital age can be overwhelming,
                          but with MedicalGPT, you have a reliable resource to
                          guide you. By providing accurate, evidence-based
                          information, personalized advice, and up-to-date
                          insights, MedicalGPT helps you take control of your
                          health and make informed decisions. In a world where
                          misinformation is rampant, having a trusted tool like
                          MedicalGPT is essential for anyone looking to manage
                          their health effectively.
                        </p>
                      </div>
                    </div>
                    <div class="bd-tags">
                      <div class="tags-left">
                        <p>Tags:</p>
                        <div class="tags-content">
                          <a href="blog">Nature</a>
                          <a href="blog">Health</a>
                        </div>
                      </div>
                      <div class="tags-right">
                        <p>Share:</p>
                        <ul class="social">
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="blog-details__pagination">
                    <div class="row items-gap">
                      <div class="col-md-6">
                        <div class="single">
                          <a href="telemedicine">
                            <i class="fa-solid fa-arrow-left-long"></i>
                            Previous Blog
                          </a>
                          <div class="latest-single">
                            <div class="latest-thumb">
                              <a href="telemedicine">
                                <img
                                  src="assets/images/news/eleven.png"
                                  alt="Image"
                                />
                              </a>
                            </div>
                            <div class="latest-content">
                              <p>10/01/2023</p>
                              <p>
                                <a href="telemedicine">
                                  Guide dog shortage: The blind peo ple who
                                  train their own guide
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="blog-main__sidebar">
                  
                    <div class="widget">
                      <div class="widget__head">
                        <h5 class="h5">Recent Posts</h5>
                      </div>
                      <div class="widget__latest">
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="600ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/ten.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="900ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/eleven.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="1200ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/twelve.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="1500ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/thirteen.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="widget">
                      <div class="widget__head">
                        <h5 class="h5">Tags</h5>
                      </div>
                      <div class="widget__tags">
                        <ul>
                          <li>
                            <a href="blog">nature</a>
                          </li>
                          <li>
                            <a href="blog">health</a>
                          </li>
                          <li>
                            <a href="blog">galaxy</a>
                          </li>
                          <li>
                            <a href="blog">creative</a>
                          </li>
                          <li>
                            <a href="blog">art</a>
                          </li>
                          <li>
                            <a href="blog">business</a>
                          </li>
                          <li>
                            <a href="blog">space</a>
                          </li>
                          <li>
                            <a href="blog">biology</a>
                          </li>
                          <li>
                            <a href="blog">environemnt</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="widget widget-big wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <a href="blog-single">
                        <img
                          src="assets/images/news/fourteen.png"
                          alt="Image"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>

        <div class="progress-wrap">
          <svg
            class="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- ==== js dependencies start ==== -->
        <!-- jquery --> */}
        <script src="/assets/js/jquery-3.7.0.min.js"></script>
        {/* <!-- bootstrap five js --> */}
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- nice select js --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- magnific popup js --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- slick js --> */}
        <script src="/assets/js/slick.min.js"></script>
        {/* <!-- typed js --> */}
        <script src="/assets/js/typed.umd.js"></script>
        {/* <!-- wow js --> */}
        <script src="/assets/js/wow.min.js"></script>
        {/* <!-- ==== / js dependencies end ==== --> */}
        {/* <!-- plugins js --> */}
        <script src="/assets/js/plugins.js"></script>
        {/* <!-- main js --> */}
        <script src="/assets/js/main.js"></script>
      </body>
    </div>
  );
}
