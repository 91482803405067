import React, { useState } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const CreateProfile = ({ onProfileCreated }) => {
  const [skipForm, setSkipForm] = useState(null); // Initial state is null
  const [formData, setFormData] = useState({
    age: "",
    weight: "",
    height: "",
    medication: "",
    diseases: "",
    heart_rate: "",
    blood_pressure: "",
    glucose_level: "",
  });

  const handleSkip = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/user-personal-info/create/`,
        {}, // Empty body for skipping
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Profile created successfully (skipped).");
      onProfileCreated(); // Notify parent component
    } catch (error) {
      console.error("Error creating profile:", error);
      toast.error("Failed to create profile.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/user-personal-info/create/`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Profile created successfully.");
      onProfileCreated(); // Notify parent component
    } catch (error) {
      console.error("Error creating profile:", error);
      toast.error("Failed to create profile.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex justify-center items-center pt-4 w-full">
      <Toaster position="top-right" />
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl ">
        {skipForm === null ? (
          // Show the "Skip" message first
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Create Your Profile</h2>
            <p className="text-gray-600 mb-6">
              You can skip this form, but filling it out helps us give you better
              recommendations.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setSkipForm(true)}
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Skip
              </button>
              <button
                onClick={() => setSkipForm(false)}
                className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition"
              >
                Fill Form
              </button>
            </div>
          </div>
        ) : skipForm ? (
          // User chose to skip
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Skip Profile Creation</h2>
            <p className="text-gray-600 mb-6">
              You have chosen to skip the form. Click below to confirm.
            </p>
            <button
              onClick={handleSkip}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Confirm Skip
            </button>
          </div>
        ) : (
          // User chose to fill the form
          <form onSubmit={handleSubmit}>
            <h2 className="text-2xl font-bold mb-6 text-center">
              Create Your Profile
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700">Age</label>
                <input
                  type="number"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your age"
                />
              </div>
              <div>
                <label className="block text-gray-700">Weight (kg)</label>
                <input
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your weight"
                />
              </div>
              <div>
                <label className="block text-gray-700">Height (ft)</label>
                <input
                  type="text"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your height"
                />
              </div>
              <div>
                <label className="block text-gray-700">Medication</label>
                <input
                  type="text"
                  name="medication"
                  value={formData.medication}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your medications"
                />
              </div>
              <div>
                <label className="block text-gray-700">Diseases</label>
                <input
                  type="text"
                  name="diseases"
                  value={formData.diseases}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter any diseases"
                />
              </div>
              <div>
                <label className="block text-gray-700">Heart Rate (BPM)</label>
                <input
                  type="number"
                  name="heart_rate"
                  value={formData.heart_rate}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your heart rate"
                />
              </div>
              <div>
                <label className="block text-gray-700">Blood Pressure</label>
                <input
                  type="text"
                  name="blood_pressure"
                  value={formData.blood_pressure}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your blood pressure"
                />
              </div>
              <div>
                <label className="block text-gray-700">Glucose Level (mg/dl)</label>
                <input
                  type="text"
                  name="glucose_level"
                  value={formData.glucose_level}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your glucose level"
                />
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={() => setSkipForm(true)}
                className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transition"
              >
                Skip
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateProfile;