import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", description: "", status: false });
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

 
    const fetchEvents = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/client_handler/api/get-events/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data.events || []);
        console.log(response.data, "API Response");

        setLoading(false);
      } catch (err) {
        setError("Failed to fetch events.");
        setLoading(false);
      }
    };
  
    const fetchEventTypeOptions = async () => {
      try {
        const token = localStorage.getItem("token");
  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/client_handler/api/event-name-choices/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEventTypeOptions(response.data || []);
      } catch (err) {
        toast.error("Failed to fetch event types.");
      }
    };
    useEffect(() => {
    fetchEvents(); // Fetch events initially
    fetchEventTypeOptions(); // Fetch event types initially
  }, []);

  const handleToggleStatus = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/api/event/${id}/toggle/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Status updated successfully!");
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id ? { ...event, status: response.data.is_active } : event
          )
        );
      } else {
        toast.error("Unexpected response. Please try again.");
      }
    } catch (err) {
      toast.error("Failed to update status. Please try again.");
    }
  };
  const handleCreateEvent = () => {
    setShowModal(true);
  };
  const handleEditEvent = (event) => {
    setSelectedEventId(event.id);
    setIsEditMode(true);
    setFormData({
      name: event.name,
      description: event.description,
      status: event.status,
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditMode(false);

    setFormData({ name: "", description: "", status: false });
  };
 
  const handleDeleteEvent = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/client_handler/api/delete-event/${id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response,"delete")
      if (response.status === 200) {
        toast.success("Event deleted successfully!");
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
      } else {
        toast.error("Unexpected response. Please try again.");
      }
    } catch (err) {
      toast.error("Failed to delete event. Please try again.");
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const formDataToSend = {
      name: formData.name,
      description: formData.description,
      status: formData.status,
    };
console.log(formDataToSend,"formDataToSend")
    try {
      if (isEditMode) {
        // Update API call
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/client_handler/api/update-event/${selectedEventId}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Event updated successfully!");
          // setEvents((prevEvents) =>
          //   prevEvents.map((event) =>
          //     event.id === selectedEventId ? { ...event, ...response.data.event } : event
          //   )
          // );
          fetchEvents();    handleCloseModal();
          
        } else {
          toast.error("Unexpected response. Please try again.");
        }
      } else {
        // Create API call
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/api/event/create/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
    console.log(response,"response create")
        if (response.status === 200) {
          toast.success("Event created successfully!");
          fetchEvents();
          handleCloseModal();
        } else {
          toast.error("Unexpected response. Please try again.");
        }
      }
    } catch (err) {
      toast.error("Failed to process request. Please try again.");
    }
  };
  return (
    <div >
        
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
       <button
        onClick={handleCreateEvent}
        type="submit"
                aria-label="post comment"
                className="btn btn--ocotonary"
                style={{
                
                    backgroundColor: "#007bff",
                    color: "#fff",
                  cursor: "pointer",
                
                }}
              >
           Create New Event
           </button>
     
     
      {loading ? (
        <p>Loading events...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : events.length === 0 ? (
        <p>No existing event.</p>
      ) : (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <th style={thStyle}>Name</th>
              <th style={thStyle}>Description</th>
              <th style={thStyle}>Update Time</th>
              <th style={thStyle}>Schedule Time</th>
              {/* <th style={thStyle}>Status</th> */}
              <th style={thStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td style={tdStyle}>{event.name || ""}</td>
                <td style={tdStyle}>{event.description}</td>
                <td style={tdStyle}>
                  {new Date(event.updated_at).toLocaleString()}
                </td>
                <td style={tdStyle}>
                  {new Date(event.schedule).toLocaleString()}
                </td>
                {/* <td style={tdStyle}>
                  {event.status ? "Active" : "Inactive"}
                </td> */}
                <td className="flex" style={tdStyle}>
                  <button
                    onClick={() => handleToggleStatus(event.id)}
                    style={{
                      padding: "6px 12px",
                      borderRadius: "4px",
                      border: "none",
                      backgroundColor: event.status ? "#dc3545" : "#28a745",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    {event.status ? "Disable" : "Enable"}
                  </button> <MdModeEdit 
                    onClick={() => handleEditEvent(event)}
                    style={{
                      marginTop:"10px",
                      marginLeft: "10px",
                      color: "#007bff",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                   <FaTrash
                    onClick={() => handleDeleteEvent(event.id)}  
                    style={{
                      marginTop:"10px",
                      marginLeft: "10px",
                      color: "#dc3545",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

 {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "16px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              width: "300px",
            }}
          >
            <h3 style={{ marginBottom: "16px", fontSize: "18px", textAlign: "center" }}>
              {isEditMode ? "Edit Event" : "Create New Event"}
            </h3>
            <form onSubmit={handleFormSubmit}>
              <div style={{ marginBottom: "12px" }}>
                <label style={{ display: "block", marginBottom: "4px", fontSize: "14px" }}>
                  Event Type:
                </label>
                <select
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  required
                >
                  <option value="">Select Event Type</option>
                  {eventTypeOptions.map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.key}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <label style={{ display: "block", marginBottom: "4px", fontSize: "14px" }}>
                  Description:
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                    resize: "none",
                    height: "60px",
                  }}
                  required
                ></textarea>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <label style={{ display: "block", marginBottom: "4px", fontSize: "14px" }}>
                  Status:
                </label>
                <select
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value === "true" })}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                  required
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "8px 12px",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {isEditMode ? "Update" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={handleCloseModal}
                  style={{
                    marginLeft: "8px",
                    backgroundColor: "#6c757d",
                    color: "#fff",
                    padding: "8px 12px",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
const thStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f2f2f2",
  };
  
  const tdStyle = {
    border: "1px solid #ddd",
    padding: "8px",
  };
export default Event;
